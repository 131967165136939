import Vue from 'vue'

export default {

	data: function() {

		return {
			is: {
				loading: true,
				initialised: false
			},
			filter: {
				search: ''
			},
			pagination: {
				perpage: 1000,
				page: 1
			},
			selected: [],
			index: [],
			total: 0,
			collection: [],
			changedLive: ''
		}

	},

	created: function() {

		this.pagination.page = parseInt(this.$route.params.pagination) || 1

		this.load()

	},

	watch: {

		$path: function() {

			this.pagination.page = parseInt(this.$route.params.pagination) || 1

		},

		pagination: {
			deep: true,
			handler: function() {

				this.load()

			}
		},

		filter: {
			deep: true,
			handler: function() {

				this.load()

			}
		}

	},

	computed: {

		sortedIndex: function() {

			return this.$_.pluck(this.collection, 'id')

		},	

		selectedIndex: function() {

			return (this.selected.length) ? this.selected : this.index

		}

	},

	methods: {

		getParams: function() {

			var params = {}

			if (this.pagination) {

				this.$_.each(this.pagination, function(value, key) {

					if (value) params[key] = value

				})

			}

			if (this.filter) {

				this.$_.each(this.filter, function(value, key) {

					if (value) params[key] = value

				})

			}

			if (this.$route.meta.params) {

				this.$_.each(this.$route.meta.params, function(value, key) {

					params[key] = value

				})

			}

			return params

		},

		load: async function(reload) {

			reload = reload || false

			if (!reload) this.is.loading = true

			var params = this.getParams()

			this.$api.cancel()

			this.$api.get(this.$endpoint(this.endpoint), params).then(function(json) {

				this.collection = json
				this.total = json.length
				this.index = this.$_.pluck(json, 'id')

				this.is.loading = false
				this.is.initialised = true

			}.bind(this))

		},

		onSorted: function(newIndex) {

			this.is.loading = true

			this.$api.put(this.$endpoint(this.endpoint), {
				old: this.selectedIndex,
				new: newIndex
			}).then(function() {

				var newOrder = []

				this.$_.each(newIndex, function(key) {

					newOrder.push(this.$util.collection.get(this.collection, key))

				}.bind(this))

				Vue.set(this, 'collection', newOrder)

				this.is.loading = false

			}.bind(this))
			
		},

		onDeleteClick: function(id) {

			this.$router.push({
				name: this.$endpoint(this.edit || this.$route.meta.edit).replace(/.Edit$/, '.Delete'),
				params: {
					id: id || this.selected.join(',')
				}
			})
			
		},

		onCreateClick: function() {

			this.$router.push({
				name: this.$endpoint(this.edit || this.$route.meta.edit),
				params: {
					id: 'new'
				}
			})

		},

		onSelectToggle: function(id) {

			this.$util.array.toggle(this.selected, id)

		},

		isSelected: function(id) {

			return this.$_.contains(this.selected, id)

		},

		onEditClick: function(id) {

			this.$router.push({
				name: this.$endpoint(this.edit || this.$route.meta.edit),
				params: {
					id: id
				}
			})

		}

	}

}