<template>

<app-content :loading="is.loading && !is.initialised">

	<app-content-head title="Organisations" subtitle="Manage organisations.">

		<app-content-head-actions-item text="Create" icon="create" v-on:click="onCreateClick" />

	</app-content-head>

	<app-content-body :loading="is.loading && is.initialised" :is-empty="!collection.length" placeholder="Sorry, no organisations found.">

		<app-table>

			<app-table-head :layout="layout">

				<app-table-column text="Name" />

			</app-table-head>

			<app-table-body>

				<app-table-row v-on:click="onEditClick(item.id)" :layout="layout" v-for="item in collection" :key="item.id">

					<app-table-cell-text :text="item.name" />

				</app-table-row>

			</app-table-body>

			<app-table-foot :pagination="pagination" :total="total" />

		</app-table>

	</app-content-body>

</app-content>

</template>

<script>

import mixCollection from '@/mixins/collection'

export default {

	mixins: [mixCollection],

	data: function() {

		return {
			layout: 'auto',
			endpoint: 'organisations',
			edit: 'Organisation'
		}

	}

}

</script>

<style scoped>

</style>